<script setup>
const config = useRuntimeConfig()
useHead({
  titleTemplate: '%s - ' + config.public.siteName,
  link: [
    {
      rel: 'preconnect',
      href: 'https://fonts.googleapis.com',
    },
    {
      rel: 'preconnect',
      href: 'https://fonts.gstatic.com',
      crossorigin: '',
    },
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible:wght@400;700&display=swap',
      crossorigin: '',
    },
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/icon?family=Material+Icons',
    },
  ],
})
</script>

<template>
  <div>
    <AppHeader />
    <slot />
  </div>
</template>

<style lang="scss">
@import '@/assets/styles/global.scss';
</style>