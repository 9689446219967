<script setup>
import { useNavigationStore } from '~/stores/navigationStore'
import { routes } from '~/config/constants'

const store = useNavigationStore()

const { step } = storeToRefs(store)

const steps = reactive(routes)

// Go to defined step when clicked on step item
watch(step, (newValue) => {
    store.goToStep(newValue)

    // Disable all steps when we've reached the last step
    routes.forEach((route, index) => {
        steps[index].disabled = false
        if (step.value === (routes.length - 1)) {
            steps[index].disabled = true
        }
    })
})

</script>

<template>
  <div
    v-if="step !== (routes.length - 1)"
    class="layout"
  >
    <va-stepper
      v-model="step"
      color="textPrimary"
      :steps="steps"
      controls-hidden
      next-disabled
    >
        <template
            v-for="(item, i) in steps"
            :key="item.label"
            #[`step-button-${i}`]="{ setStep, isActive, isCompleted }"
        >
            <li
                class="va-stepper__step-button"
                :class="{
                  'va-stepper__step-button--active': (isActive || isCompleted),
                  'va-stepper__step-button--disabled': (!isActive && !isCompleted),
                  'va-stepper__step-button--current': step  === i,
                }"
                :style="(isActive || isCompleted) ? '--va-0-stepper-color: #262824;' : null"
                :tabindex="i"
                @click="setStep(i)"
            >
                <div class="va-stepper__step-button__icon">{{i + 1}}</div>
                <span>{{ item.label }}</span>
            </li>
        </template>
    </va-stepper>
  </div>
</template>

<style scoped>

</style>
