<script setup>
const config = useRuntimeConfig()
</script>

<template>
  <va-alert
    v-if="config.public.test_env === 'production'"
    color="danger"
    icon="warning"
    class="text-center mb-5"
  >
    Let op, deze omgeving maakt gebruik van <strong>Productie</strong> gegevens!
  </va-alert>
  <va-navbar>
    <template #left>
      <va-navbar-item class="logo">
        <img
          src="~/assets/img/muzieum-logo.svg"
          :alt="config.public.siteName"
        >
      </va-navbar-item>
    </template>
    <template #right>
      <va-navbar-item>
        <va-button
          class="primary hide-mobile"
          round
          icon="arrow_back"
          to="/"
        >
          {{ $t('progress.home') }}
        </va-button>
      </va-navbar-item>
    </template>
  </va-navbar>
  <ProgressSteps />
</template>
